<template>
  <div class="rehabilitationDetails">
    <router-link to="/rehabilitation"><img src="@/assets/img/goback_icon.svg" class="gobackBtn"></router-link>
    <h1 class="title">{{details.title}}</h1>
    <p class="date">{{details.date}}</p>
    <!-- v-html="details.content" -->
    <div class="content" v-html="details.content">
     
    </div>
    <img src="@/assets/img/returnTop.svg" class="returnTop" @click="toTop">
  </div>
</template>
<script>

import detailsCont from '@/utils/reDetailsCont'
export default {
  name: "RehabilitationDetails",
  data() {
    let config = []
    Object.assign(config, detailsCont)
    return {
      detailsCont: config,
      details: {},
      myTimer:-1
    }
  },
  methods: {
    toTop() {
      let scrollTo = document.documentElement.scrollTop;
      if (this.myTimer == -1) {
        if (scrollTo > 10000) {
          let num = (scrollTo / 80).toFixed(0);
          this.myTimer = setInterval(() => {
            scrollTo -= num;
            if (scrollTo <= 0) {
              scrollTo = 0;
              window.clearInterval(this.myTimer);
              this.myTimer = -1;
            }
            window.scrollTo(0, scrollTo);
          }, 10);
        } else {
          this.myTimer = setInterval(() => {
            scrollTo -= 100;
            if (scrollTo <= 0) {
              scrollTo = 0;
              window.clearInterval(this.myTimer);
              this.myTimer = -1;
            }
            window.scrollTo(0, scrollTo);
          }, 10);
        }
      }
    },
  },
  mounted() {
    switch (this.$route.query.category) {
      case 'appraisal':
        this.details = this.detailsCont[0]
        break;
      case 'gaitrace':
        this.details = this.detailsCont[1]
        break;
      case 'vr':
        this.details = this.detailsCont[2]
        break;
      case 'car':
        this.details = this.detailsCont[3]
        break;
      default:
    }
  }
}
</script>

<style lang="scss" scoped>
.rehabilitationDetails {
  padding: 0 0.16rem;
  background: #fff;
  overflow: hidden;
  .returnTop {
    position: fixed;
    right: 0.1rem;
    bottom: 0.7rem;
  }
  .gobackBtn {
    margin: 0.16rem 0;
    display: block;
    width: 0.31rem;
    height: 0.31rem;
  }
  .title {
    font-size: 0.18rem;
  }
  .date {
    font-size: 0.1rem;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 0.08rem;
  }
  :deep(.content) {
    // img{
    //   display: block;
    //   margin: 0.3rem auto;
    //   border-radius: 0.08rem;
    // }
    // p {
    //   margin-top: 0.24rem;
    //   font-size: 0.14rem;
    //   line-height: 0.24rem;
    // }

    img {
      display: block;
      margin: 0.2rem auto;
      border-radius: 0.08rem;
      width: 100%;
    }
    p {
      margin-top: 0.2rem;
      font-size: 0.14rem;
      line-height: 0.24rem;
      span {
        color: #34aabd;
      }
    }
    h3 {
      margin-top: 0.3rem;
      background: url("/articleImg/title_bg.png") no-repeat center;
      height: 0.52rem;
      line-height: 0.52rem;
      text-align: center;
      color: #0596ad;
      font-size: 0.18rem;
    }
    .center {
      text-align: center;
    }
    .widthAuto {
      width: 2.4rem;
    }
    .label {
      height: 0.46rem;
      line-height: 0.46rem;
      text-align: center;
      font-size: 0.14rem;
      border-radius: 4px;
      background: rgba(219, 254, 255, 0.4);
      box-sizing: border-box;
      border: 1.2px solid rgba(7, 185, 185, 0.2);
      color: #07b9b9;
    }
    h4{
      font-size: 0.14rem;
      line-height: 0.24rem;
      margin-top: 0.36rem;
      margin-bottom: -0.12rem;
    }
  }
}
</style>